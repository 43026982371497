import * as React from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import s from './ViewMore.scss';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {compose} from '../../../commons/utils';
import classNames from 'classnames';

function ViewMoreComponent({
  t,
  globals: {navigate, product, experiments},
}: ProvidedGlobalProps & IProvidedTranslationProps) {
  return (
    <a
      data-hook="quick-view-more"
      className={classNames(s.root, {[s.fixViewMoreFont]: experiments.fixQuickViewMoreLinkFontFallback})}
      onClick={() => navigate(product.urlPart, true)}>
      {t('productPage.quickView.viewProductDetails')}
    </a>
  );
}

export const ViewMore = compose(withTranslations('globals.texts'), withGlobalProps)(ViewMoreComponent);
