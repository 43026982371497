import {RichContent} from 'ricos-schema';
import {isRichContent} from 'ricos-content';

export const getRicoRichContent = (content: string) => {
  try {
    const richContent = JSON.parse(content) as RichContent;
    /* istanbul ignore next: tested in sled */
    return isRichContent(richContent) ? richContent : null;
  } catch {
    return null;
  }
};

export const isRicoRichContent = (content: string) => {
  return getRicoRichContent(content) !== null;
};
