/* istanbul ignore file: code unreachable, should be removed when UseNewExpressCheckoutButtonsInProductPage FT is merged */
import React from 'react';
import {GlobalProps, ProvidedGlobalProps} from './globalPropsProvider';

export interface WithPropsChangeListenersProps {
  waitForChange?: <PropName extends keyof GlobalProps>(propName: PropName) => Promise<GlobalProps[PropName]['value']>;
}

export function withPropsChangeListeners(Component: any): React.FunctionComponent | any {
  return class WithPropsChangeListener extends React.Component<ProvidedGlobalProps> {
    private readonly propsToCheck: Map<string, {propName: string; oldCounter: number}> = new Map();
    private readonly resultsToCheckResolves: Map<string, (value: unknown) => void> = new Map();
    private counter: number = 0;

    public componentDidUpdate(): void {
      this.propsToCheck.forEach((propToCheck, key) => {
        if (this.props.globals[propToCheck.propName]?.counter !== propToCheck.oldCounter) {
          this.resultsToCheckResolves.get(key)(this.props.globals[propToCheck.propName].value);
          this.resultsToCheckResolves.delete(key);
          this.propsToCheck.delete(key);
        }
      });
    }

    private readonly waitForChange = (propName: keyof GlobalProps) => {
      const oldCounter = this.props.globals[propName]?.counter;
      const key = this.getUniqueKey();
      this.propsToCheck.set(key, {oldCounter, propName});
      let propChangeResolver;
      const propChangePromise = new Promise((resolve) => (propChangeResolver = resolve));

      this.resultsToCheckResolves.set(key, propChangeResolver);
      return propChangePromise;
    };

    private getUniqueKey() {
      return (this.counter++).toString();
    }

    public render() {
      return <Component {...this.props} waitForChange={this.waitForChange} />;
    }
  };
}
