import React from 'react';
import {Navigation} from '../../Navigation/Navigation';
import {ProductDescription} from '../../ProductDescription/ProductDescription';
import {InfoSectionLayout} from '../../InfoSectionLayout/InfoSectionLayout';
import {ProductOptions} from '../../ProductOptions/ProductOptions';
import {ProductSocial} from '../../ProductSocial/ProductSocial';
import {ProductName} from '../../ProductName/ProductName';
import {ProductSku} from '../../ProductSku/ProductSku';

import s from './SleekLayout.scss';
import {Cell} from '../Cell/Cell';
import {ProductGalleryLayout} from '../../ProductGallery/ProductGalleryLayout/ProductGalleryLayout';
import {LayoutComponentProps} from '../../../types/app-types';
import {Layout, MarginBottom, ProductPageSlotIds} from '../../../constants';
import {createLayoutConfigWithDefaults} from '../../ProductGallery/ProductGalleryLayout/ProductGalleryLayoutUtils';
import {BottomPlaceholder} from '../../Placeholders/BottomPlaceholder';
import {DetailsPlaceholder} from '../../Placeholders/DetailsPlaceholder';
import {ProductPageButtonsContainer} from '../../ProductPageButtonsContainer/ProductPageButtonsContainer';
import {ProductPrice} from '../../ProductPrice/ProductPrice';
import {ProductPaymentBreakdown} from '../../ProductPaymentBreakdown/ProductPaymentBreakdown';
import {ProductDiscountName} from '../../ProductDiscountName/ProductDiscountName';
import {RatingSummarySlot} from '../../RatingSummarySlot/RatingSummarySlot';
import {ReviewsSlot} from '../../ReviewsSlot/ReviewsSlot';
import {ProductPageTopSlot} from '../../ProductPageTopSlot/ProductPageTopSlot';
import classNames from 'classnames';
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi';

export const SleekLayout: React.FunctionComponent<LayoutComponentProps> = ({settings, globals}) => {
  const GALLERY_WIDTH = 980;
  const GALLERY_HEIGHT = 551;

  const {product, slots} = globals;
  const {renderProductPageSlots} = globals.experiments ?? {};
  const isPluginInstalledInTopSlot = !!slots?.[ProductPageSlotIds.ProductPageTop];
  const isPluginInstalledInDetails9Slot = !!slots?.[ProductPageSlotIds.ProductPageDetails9];
  const isPluginInstalledInDetails2Slot = !!slots?.[ProductPageSlotIds.ProductPageDetails2];
  const isPluginInstalledInDetails3Slot = !!slots?.[ProductPageSlotIds.ProductPageDetails3];
  const isPluginInstalledInDetails4Slot = !!slots?.[ProductPageSlotIds.ProductPageDetails4];
  const isPluginInstalledInDetails8Slot = !!slots?.[ProductPageSlotIds.ProductPageDetails8];

  return (
    <article className={s.container}>
      <div className={classNames({[s.topSlot]: isPluginInstalledInTopSlot})}>
        <ProductPageTopSlot />
      </div>
      <Cell className={s.main}>
        {settings.shouldShowNavigation && (
          <Navigation
            className={classNames({
              [s.navigation]: !isPluginInstalledInTopSlot,
              [s.navigationSmallSpacing]: isPluginInstalledInTopSlot,
            })}
          />
        )}
      </Cell>
      <div className={s.headerContainer}>
        <header className={s.header}>
          <ProductGalleryLayout
            product={product}
            layoutConfig={createLayoutConfigWithDefaults({
              marginBottom: MarginBottom.LARGE,
              dimensions: {
                mainMedia: {
                  widthConf: {num: GALLERY_WIDTH, unit: 'px'},
                  heightConf: {num: GALLERY_HEIGHT, unit: 'px'},
                },
                thumbnails: {
                  widthConf: {num: GALLERY_WIDTH, unit: 'px'},
                  heightConf: {num: 50, unit: 'px'},
                },
              },
            })}
            layout={Layout.Sleek}
            {...settings}
          />
        </header>
      </div>
      <div className={s.main}>
        <section className={s.section}>
          <Cell className={s.title}>
            <ProductName name={product.name} />
          </Cell>
          <RatingSummarySlot />
          {settings.shouldShowPrice && (
            <Cell className={s.price}>
              <ProductPrice />
            </Cell>
          )}
          {renderProductPageSlots && (
            <Cell
              className={classNames({
                [s.productPageSlot]: isPluginInstalledInDetails3Slot,
                [s.productPageSlotBottomSpacingSmall]: isPluginInstalledInDetails3Slot,
              })}>
              <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails3} />
            </Cell>
          )}

          {settings.shouldShowDiscountName && product?.itemDiscount?.discountRuleName && (
            <Cell>
              <ProductDiscountName name={product.itemDiscount.discountRuleName} />
            </Cell>
          )}

          {settings.shouldShowProductPaymentBreakdown && (
            <Cell>
              <ProductPaymentBreakdown />
            </Cell>
          )}

          <Cell>
            <DetailsPlaceholder />
          </Cell>
          <div>
            <Cell className={s.description}>
              <ProductDescription description={product.description} />
            </Cell>
          </div>
          {renderProductPageSlots && (
            <Cell className={classNames({[s.productPageSlotMediumSpacing]: isPluginInstalledInDetails4Slot})}>
              <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails4} />
            </Cell>
          )}
          <div>
            <Cell className={s.options}>
              <ProductOptions
                shouldShowQuantity={settings.shouldShowQuantity}
                shouldShowStockIndicator={settings.shouldShowStockIndicator}
              />{' '}
              <ProductPageButtonsContainer />
            </Cell>
          </div>
          {settings.shouldShowSku && (
            <Cell className={s.sku}>
              <ProductSku />
            </Cell>
          )}
          {renderProductPageSlots && (
            <Cell
              className={classNames(s.productPageSlot, {
                [s.productPageSlotBottomSpacingMedium]: isPluginInstalledInDetails2Slot,
              })}>
              <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails2} />
            </Cell>
          )}
          {settings.shouldShowSocialNetwork && (
            <Cell className={s.social}>
              <ProductSocial />
            </Cell>
          )}
          {renderProductPageSlots && (
            <Cell className={classNames({[s.productPageSlotBigSpacing]: isPluginInstalledInDetails8Slot})}>
              <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails8} />
            </Cell>
          )}
        </section>
      </div>
      <div className={s.footerContainer}>
        {settings.shouldShowInfoSection && (
          <footer className={s.footer}>
            <Cell className={s.info}>
              <InfoSectionLayout />
            </Cell>
          </footer>
        )}
      </div>
      {renderProductPageSlots && (
        <Cell className={classNames({[s.productPageSlotBottomSpacingBig]: isPluginInstalledInDetails9Slot})}>
          <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails9} />
        </Cell>
      )}
      <Cell>
        <ReviewsSlot />
        <BottomPlaceholder />
      </Cell>
    </article>
  );
};
